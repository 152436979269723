
import heroImg from '../../images/hero-image.png';
import '../Hero/Hero.css'

export default function Hero() {
    return (
        <div className="Hero">
            <img className="hero-image-mobile" src={heroImg} alt="" />
            <div className='hero-image-desktop'></div>
            <div className="hero-filter"></div>
        </div>
    )
}