import './Footer.css';

import Socials from '../Socials/Socials';

export default function Footer() {
    return (
        <div className='Footer'>
            <p className='footer-copyright'>Copyright © 2024 Suite Life Quartet, LLC<br/>Created by Zach Gould</p>
            <Socials/>
        </div>
    )
}