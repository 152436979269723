import './Contact.css';

import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile';
import ContactForm from './ContactForm';
import Footer from '../Footer/Footer';
import { useMediaQuery } from 'react-responsive';

export default function Contact() {
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});

    return (
        <div className='Contact'>
            {isDesktop ? <Navbar/> : <NavbarMobile/>}
            <div className="contact-advertisement">
                <h1>Want to book us?</h1>
                <div className='contact-advertisement-wrapper'>
                    <p>Chapter Shows</p>
                    <p>Private Events</p>
                    <p>Youth Camps</p>
                    <p>Senior Living Homes</p>
                    <p>More!</p>
                </div>
            </div>
            <div className='contact-container'>
                <h1 className='contact-title'>Contact us!</h1>
                <p className='contact-desc'>Please use the form to send us any requests that you have. We would love to hear from you!</p>
                <ContactForm/>
            </div>
            <Footer/>
        </div>
    )
}