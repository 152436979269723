import { useEffect, useState } from 'react';

import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile';
import ListenRow from './ListenRow';
import Footer from '../Footer/Footer';
import { useMediaQuery } from 'react-responsive';

export default function Listen() {
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log(entries);
        })

        
    },[])

    return (
        <div className='Listen'>
            {isDesktop ? <Navbar/> : <NavbarMobile/>}

            <h1 className='listen-title'>Latest Performance</h1>
            <div className="listen-main-video">
                {   false ?
                    <p>Video Coming Soon!</p> :
                    <iframe src="https://www.youtube.com/embed/qWKm_2uPEG8?si=XOME2Sq0ZoReN-S6" title="Suite Life • Oh! Look At Me Now • 2024 Varsity Quartet Finals" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
                }
            </div>
            <div className='listrow-details' style={isDesktop ? {width: '50%'} : {width: '80%'}}>
                <h2>{"Oh! Look At Me Now"}</h2>
                <h4>{"Aaron Dale"}</h4>
                <div className='listrow-divider'></div>
                <p>{"This song was a lot of fun for us to sing on the international stage for the 2024 competition! You could say we... \"gave it some zazz\". We hope you enjoy our up-tune for our 2024 NextGen Varsity International contest set!"}</p>
            </div>
            <h1 className='listen-sub-title'>Other Performances</h1>
            <div className='listenrow-container'>
                <ListenRow url={"https://www.youtube.com/embed/DDvhDXh2I5s?si=WafciyyjFzMbVjvR"} title={"Suite Life • Maybe This Time • 2024 Varsity Quartet Finals"} vTitle={"Maybe This Time"} arr={"Arr. Clay Hine"} desc={"Maybe This Time is most definitely one of our favorite ballads to sing. It is very easy to get into the story and tell it with such intensity. We hope you enjoy it as much as we do!"} direction={"left"}/>
                <ListenRow url={"https://www.youtube.com/embed/wMr0x7QfLZg"} title={"Suite Life • Shine • arr. David Wright • 2023 Next Gen Varsity Contest"} vTitle={"I Didn't Want To Fall"} arr={"Arr. Joe Liles"} desc={"We are ecstatic about our Sunshine Fall Districts performance! We're honored to have been placed 3rd in this competition, right under some phenomenal quartets. All the quartets for the 2023 Sunshine Districts Fall contest were incredible, which made for a tough, but fun competition! Enjoy one of our songs in our Districts set."} direction={"right"}/>
                <ListenRow url={"https://www.youtube.com/embed/J_KhhNAw-gg"} title={"Suite Life • Shine • arr. David Wright • 2023 Next Gen Varsity Contest"} vTitle={"Once Upon a Time"} arr={"Arr. Rob Campbell"} desc={"We've made our first major debut as a quartet on the International stage! We're very grateful to have had this opportunity in Kentucky to join the barbershop community and sing alongside so many amazing people. Enjoy our performance!"} direction={"left"}/>
                <ListenRow url={"https://www.youtube.com/embed/L-DtreKkhcQ"} title={"Suite Life • Shine • arr. David Wright • 2023 Next Gen Varsity Contest"} vTitle={"Shine"} arr={"Arr. David Wright"} desc={'Following our first song at our NextGen Varsity Internationals set is "Shine". As much as we enjoy ballads, we especially enjoy our uptempo songs. Watch our performance of "Shine" with a little homeage to some "Gashouse Gang" choreography!'} direction={"right"}/>
            </div>
            <Footer/>
        </div>
    )
}