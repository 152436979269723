import { useRef } from "react";
import { Link } from "react-router-dom";

import logo from '../../images/suite-life-logo.png';
import Navlink from "./Navlink";
import Socials from "../Socials/Socials";

import './Navbar.css';

export default function Navbar() {
    return(
        <div className="Navbar">
            <Navlink name={"About Us"} url={'/about-us'} tx="-50px" delay="500ms"/>
            <Navlink name={"Events"} url={'/events'} tx="-50px" delay="350ms"/>
            <Link to={'/'}><img className="navbar-logo" src={logo}/></Link>
            <Navlink name={'Listen'} url={'/listen'} tx="50px" delay="350ms"/>
            <Navlink name={"Contact Us"} url={'/contact-us'} tx="50px" delay="500ms"/>
            <div className="navbar-socials">
                <Socials/>
            </div>
        </div>
    )
}