import logo from './logo.svg';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import NavbarMobile from './components/Navbar/NavbarMobile';
import Hero from './components/Hero/Hero';
import Video from './components/Video/Video';
import Instagram from './components/Socials/Instagram';
import ContactForm from './components/Contact/ContactForm';
import Socials from './components/Socials/Socials';
import Footer from './components/Footer/Footer';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function App() {
  const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});

  return (
    <div className="App">
        {isDesktop ? <Navbar/> : <NavbarMobile/>}
        
        <Hero/>
        <div className='app-container'>
          <h1 className='app-title'>Hello, we're <strong>Suite Life Quartet!</strong></h1>
          <Video link={"https://www.youtube.com/embed/qWKm_2uPEG8?si=XOME2Sq0ZoReN-S6"} title={"Suite Life • Oh! Look At Me Now • 2024 Varsity Quartet Finals"} outline={'white'} isActive={true}/>
          <p className='app-text'><strong>Suite Life</strong> &nbsp;is currently the 2023 Sunshine District 3rd place quartet, 2023 Sunshine District Youth Quartet Champions, and recently named the 2024 Next Generation International Silver Medalists!</p>
          <p className='app-text'>They formed on January 24th, 2023 - Just a week before the 2023 Next Generation Varsity auditions were due! Luckily, they found success in qualifying. From there, they have attended almost every major Barbershop event in the Sunshine District. Feeling motivated by their growth, they auditioned for the 2024 Next Generation Varsity International Competition where they seeded and finished 2nd.</p>
          <p className='app-text'>The four attended Rollins College in Winter Park, Florida for their undergraduate music degree, where they grew together as musicians. They continue to share their love of music through the wonderful art form that is Barbershop.</p>
        </div>
        <div className="app-socials">
          <div className="app-socials-left">
            <Instagram/>
          </div>
          <div className="app-socials-right">
            <h1 className='app-socials-title'>Check out our socials!</h1>
            <Socials/>
            <p className='app-socials-email'>Email us at <a href="mailto:contact@suitelifeqt.com">contact@suitelifeqt.com</a></p>
          </div>
        </div>
        <h2 className='app-or-title'>OR</h2>
        <div className="app-contact">
          <h1 className='app-contact-title'>Send us a message!</h1>
          <ContactForm/>
        </div>
      <Footer/>
    </div>
  );
}

export default App;
