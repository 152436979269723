import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider,createBrowserRouter } from 'react-router-dom';

import App from './App';
import Events from "./components/Events/Events";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Listen from "./components/Listen/Listen";
import NotFound from "./components/NotFound/NotFound";

const router = createBrowserRouter([
  { path: '/', element: <App/>},
  { path: '/about-us', element: <About/>},
  { path: '/events', element: <Events/>},
  { path: '/listen', element: <Listen/>},
  { path: '/contact-us', element: <Contact/>},
  { path: '*', element: <NotFound/>}
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
