import { Link } from "react-router-dom"
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

import './Navbar.css';

export default function Navlink({url,name,tx,delay}) {
    const prev = useRef();
    const post = useRef();
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});

    function handleMouseOver() {
        if(isDesktop) {
            prev.current.style.transform = 'translateY(-40px)';
            post.current.style.transform = 'translateY(-40px)';
        }        
    }

    function handleMouseOut() {
        if(isDesktop) {
            prev.current.style.transform = 'translateY(0px)';
            post.current.style.transform = 'translateY(0px)';
        }
    }

    return (
        <Link className="navbar-link" to={url} style={{transform: `translateX(${tx})`,animationDelay: delay}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <p ref={prev} className="navbar-link-prev">{name}</p>
            <p ref={post} className="navbar-link-post">{name}</p>
        </Link>
    )
}