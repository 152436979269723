import { useMediaQuery } from 'react-responsive';

import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile';
import AboutRow from './AboutRow';
import Footer from '../Footer/Footer';
import Construction from '../Construction/Construction';

import trippHeadshot from '../../images/tripp-headshot.png';
import zachHeadshot from '../../images/zach-headshot.png';
import lexHeadshot from '../../images/lex-headshot.png';
import codyHeadshot from '../../images/cody-headshot.png';

export default function About() {
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});

    return (
        <>
            <div className='About'>
                {/* <Construction/> */}
                {isDesktop ? <Navbar/> : <NavbarMobile/>}

                <AboutRow url={lexHeadshot} name={"Lex Goity"} part={"Tenor"} bio={"Lex Goity, 25, is the Tenor of Suite Life. He is an active performing vocalist based out of Orlando, Florida. In addition to performing with his barbershop quartet Suite Life, he sings regularly with the Basilica Choir at the National Shrine of Mary Queen of the Universe and Orlando Sings’ award winning chamber ensemble Solaria Singers, performs with Entertainment Central Productions, and is on the roster for Choral Chameleon’s auditioned choral ensemble in Manhattan, NYC. Lex is a graduate of Rollins College and the Crummer Graduate School of Business, where he earned his BA in Music and his MBA. In his spare time, Lex enjoys trying new recipes and going for runs with his corgi, Winston."}/> 
                <AboutRow url={codyHeadshot} name={"Cody Mathewson"} part={"Lead"} bio={"Cody Mathewson, 22, is the Lead of Suite Life. A home grown Florida boy, he has recently graduated with a degree in vocal performance from Rollins College. In addition to Suite Life, Cody is a member of the Heralds of Harmony under the direction of Tony DeRosa. Aside from Barbershop, Cody is also an Orlando Singer/Songwriter who frequently performs in the city. He is also the Founder/ Music Director of The Orange Blossoms, a local Classic Rock/ Jazz Fusion band. Cody has also had the privilege to collaborate with many artists including; The Royal Philharmonic Orchestra, Bach Festival Society of Winter Park, Voctave, Hannah Stokes and many others."}/> 
                <AboutRow url={zachHeadshot} name={"Zach Gould"} part={"Bass"} bio={"Zach Gould, 24, is the Bass of Suite Life. He is a 2022 graduate of Rollins College, where he earned his BA in Computer Science and Music. Growing up in a musical household, Zach's love for music blossomed early on; he sang in a boy choir during his youth and continued to participate in choirs throughout middle school, high school, and college. Zach's earliest Barbershop experiences are of his father taking him to a few rehearsals at the Orange Blossom Chorus. Currently, Zach works as a Technical Support Specialist and Web Developer at a financial broker-dealer. Outside of his professional life, Zach is a dedicated car enthusiast who enjoys attending car shows whenever he gets the chance."}/> 
                <AboutRow url={trippHeadshot} name={"Tripp Carter"} part={"Baritone"} bio={"Tripp Carter, 22, is the Baritone of Suite Life. Originally from Augusta, Georgia, he recently graduated from Rollins College in Winter Park, Florida with a music degree. Additionally, Tripp is a member of The Heralds of Harmony under the direction of Tony De Rosa. Beyond barbershop, he's had the privilege of collaborating with notable artists including The Royal Philharmonic Orchestra, The Bach Festival Society of Winter Park, Voctave, and others. As an active choral arranger, Tripp's compositions have graced the repertoire of collegiate, church, middle, and high school ensembles, as well as Grammy Award-winning vocal groups. He is proud to be published with both Hal Leonard and Excelcia Music Publishing."}/>        
            </div>
            <Footer/>       
        </>
    )
}