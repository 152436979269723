import { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

import './Contact.css';

export default function ContactForm() {
    const form = useRef();
    const name = useRef();
    const email = useRef();
    const message = useRef();
    const recaptcha = useRef();
    const [warning,setWarning] = useState(null);
    const [success,setSuccess] = useState(null);

    async function handleFormSubmit(event) {
        event.preventDefault();

        name.current.value.length == 0 ? 
        name.current.className = 'input-empty' : 
        name.current.className = '';  

        email.current.value.length == 0 ? 
        email.current.className = 'input-empty' : 
        email.current.className = '';  

        message.current.value.length == 0 ? 
        message.current.className = 'input-empty' : 
        message.current.className = '';

        const isEmptyForm = document.querySelector('.input-empty');

        if(isEmptyForm) {
            setWarning(<div className='warning'>Please fill in all fields</div>);
        // } else if(recaptcha.current.getValue() === '') {
        //     setWarning(<div className='warning'>Please complete the ReCaptcha</div>);
        } else {
            setWarning(null);

            form.current.classList.add('form-complete');
            emailjs.sendForm('service_fj9xl3v', 'template_h0k6tqi', form.current, '19D5r_sW-l-fqo2mF')
            .then(resp => {
                form.current.remove();

                if(resp.status == 200) {
                    setSuccess(<div className='success'>
                        <p className='success-title'>Your message was successfully sent!</p>
                        <p className='success-desc'>Thank you for contacting us. Someone will be in touch with you soon!</p>
                    </div>);
                } else {
                    setSuccess(<div className='success'>
                        <p className='fail-title'>Oops! There was an issue sending your message.</p>
                        <p className='fail-desc'>Please email us directly at <a href="mailto:contact@suitelifeqt.com">contact@suitelifeqt.com</a>, and let us know of the issue. Thanks!</p>
                    </div>);
                }
            })
            .catch(err => {
                console.error(err);
            })

            // await fetch('/recaptcha', {
            //     method: 'post',
            //     headers: {'content-type':'application/json'},
            //     body: JSON.stringify({captchaValue: recaptcha.current.getValue()})
            // })
            // .then(res => res.json()).then(response => {
            //     console.log(response);

            //     if(response.success){
                    
            //     } else {
            //         setWarning(<div className='warning'>ReCaptcha validation failed</div>);
            //     }
            // })
            // .catch(err => {
            //     console.log(err);
            //     setWarning(<div className='warning'>Error occured with the ReCaptcha validation</div>);
            // })
        }
    }
    
    return (
        <>
            <form ref={form} className='ContactForm' onSubmit={handleFormSubmit}>
                <label htmlFor="">Name</label>
                <input ref={name} type="text" name="name"placeholder='John Doe'/>

                <label htmlFor="">Email</label>
                <input ref={email} type="email" name="email"placeholder='example@domain.com'/>

                <label htmlFor="">Send us a message here!</label>
                <textarea ref={message} name="message" placeholder='Enter your message here...'></textarea>

                {/* <ReCAPTCHA ref={recaptcha} className='contact-recaptcha' sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}/> */}

                {warning}

                <input type="submit" value="Send"/>
            </form>
            {success}
        </>
    )
}