import { useMediaQuery } from 'react-responsive';

import './NotFound.css';

import Navbar from "../Navbar/Navbar"
import NavbarMobile from "../Navbar/NavbarMobile"
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

export default function NotFound() {
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});
    
    return (
        <>
            <div className="NotFound">
                {isDesktop ? <Navbar/> : <NavbarMobile/>}
                <div className='notfound-container'>
                    <h1 className='notfound-title'>Uh oh!</h1>
                    <p className='notfound-desc'>Seems like we can't find the page you are looking for. If this is a mistake, please contact the website's administrator at <a href="mailto:contact@suitelifeqt.com">contact@suitelifeqt.com</a></p>
                    <Link className='notfound-return-home'>Go Back Home</Link>
                </div>
            </div>
            <Footer/>
        </>
    )
}