import './Video.css';

export default function Video({link,title,outline,isActive}) {
    return (
        <div className='Video' style={{outlineColor: outline}}>
            {isActive ? <iframe src={link} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe> : 
            <div className='video-unavailable'>
                <p>Video Coming Soon!</p>
            </div>
            }
        </div>
    )
}