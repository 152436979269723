import './Socials.css';

import facebook from '../../images/facebook-logo.png'
import instagram from '../../images/instagram-logo.png'
import youtube from '../../images/youtube-logo.png'

export default function Socials() {
    return (
        <div className='Socials'>
            <a href="https://www.instagram.com/suitelifeqt/" target="_blank" rel="noopener noreferrer"><img className="socials-img" src={instagram} alt="" /></a>
            <a href="https://www.facebook.com/profile.php?id=100093253321875" target="_blank" rel="noopener noreferrer"><img className="socials-img" src={facebook} alt="" /></a>
            <a href="https://www.youtube.com/@SuiteLifeQT" target="_blank" rel="noopener noreferrer"><img className="socials-img" src={youtube} alt="" /></a>
        </div>
    )
}