import './About.css';

export default function AboutRow({url,name,part,bio}) {

    return(
        <div className="AboutRow">
            <img className="about-row-img" src={url} alt="" />
            <div className='about-row-member'>
                <h1 className='about-row-member-name'>{name}</h1>
                <h4 className='about-row-member-part'>{part}</h4>
                <div className='about-row-member-divider'></div>
                <p className='about-row-bio'>{bio}</p>
            </div>
        </div>
    )
}