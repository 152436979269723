import { useRef,useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import logo from '../../images/suite-life-logo.png';
import Navlink from "./Navlink";
import Socials from "../Socials/Socials";

import './NavbarMobile.css';

export default function NavbarMobile() {
    const lineTop = useRef();
    const lineMiddle = useRef();
    const lineBottom = useRef();
    const [options,setOptions] = useState(null);
    const isMobile = useMediaQuery({query: '(max-width: 999px)'});

    function handleMobileMenu() {
        lineTop.current.classList.toggle('menu-top-active');
        lineMiddle.current.classList.toggle('menu-middle-active');
        lineBottom.current.classList.toggle('menu-bottom-active');

        !options ?
        setOptions(<div className="navbar-mobile-menu-options">
            <div className="navbar-mobile-menu-options-container">
                <Navlink name={"About Us"} url={'/about-us'} tx="0px" delay="0"/>
                <Navlink name={"Events"} url={'/events'} tx="0px" delay="0"/>
                <Navlink name={'Listen'} url={'/listen'} tx="0px" delay="0"/>
                <Navlink name={"Contact Us"} url={'/contact-us'} tx="0px" delay="0"/>
            </div>
            <Socials/>
        </div>) :
        setOptions(null);
    }

    return(
        <div className="NavbarMobile">
            <Link to={'/'}><img className="navbar-logo" src={logo}/></Link>
            <div className="navbar-mobile-menu-btn" onClick={handleMobileMenu}>
                <div ref={lineTop} className="navbar-mobile-menu-btn-line" style={{transform: 'translateY(-8px)'}}></div>
                <div ref={lineMiddle} className="navbar-mobile-menu-btn-line"></div>
                <div ref={lineBottom} className="navbar-mobile-menu-btn-line" style={{transform: 'translateY(8px)'}}></div>
            </div>
            
            {options}
        </div>
    )
}