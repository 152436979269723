import './Events.css';

export default function Event({url,title,date,addressLink,street,state,desc,info}) {

    return (
        <div className='Event'>
            <iframe src={url} style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className='event-information'>
                <h1 className='event-title'>{title}</h1>
                <h2 className='event-date'>{date}</h2>
                <div className='event-divider'></div>
                <a className='event-address' href={addressLink} target="_blank" rel="noopener noreferrer">
                <h4 className='event-street'>{street}</h4>
                <h4 className='event-state'>{state}</h4>
                </a>
                <a href={info} className='event-more-info' target="_blank" rel="noopener noreferrer">More Info!</a>
            </div>
        </div>
    )
}