import './Listen.css';

export default function ListenRow({url,title,vTitle,arr,desc,direction}) {
    return (
        <div className="ListenRow" style={{flexDirection: direction === "left" ? 'row' : 'row-reverse'}}>
            <div className='listrow-video' style={{justifyContent: direction === 'left' ? 'flex-end' : 'flex-start'}}>
                <iframe src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe>
            </div>
            <div className='listrow-details'>
                <h2>{vTitle}</h2>
                <h4>{arr}</h4>
                <div className='listrow-divider'></div>
                <p>{desc}</p>
            </div>
        </div>
    )
}