import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile';
import Footer from '../Footer/Footer';

import { useMediaQuery } from 'react-responsive';

import Event from './Event';

export default function Events() {
    const isDesktop = useMediaQuery({query: '(min-width: 1000px)'});
    
    return (
        <div className='event-container'>
            <div className='event-wrapper'>
                {isDesktop ? <Navbar/> : <NavbarMobile/>}
                <div className='Events'>
                    <Event url={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4109.429862344251!2d-81.46979506316825!3d29.99078450127515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e42c63b8cbf159%3A0xbd0a59d33ec97564!2sWorld%20Golf%20Village%20Renaissance%20St.%20Augustine%20Resort!5e0!3m2!1sen!2sus!4v1721353461123!5m2!1sen!2sus"} title={"SUN District 2024 Fall Convention"} date={"October 18th, 2024 - October 20th, 2024"} addressLink={"https://www.google.com/maps?ll=29.990395,-81.468224&z=15&t=m&hl=en&gl=US&mapclient=embed&cid=13621798786761848164"} street={"500 S Legacy Trail"} state={"St Augustine, FL 32092"} desc={"We're excited to see all of the amazing quartets and choruses representing the Sunshine District compete this year!"} info={"https://www.sunshinedistrict.org/event-calendar/sun-district-2024-fall-convention/"}/>

                    <Event url={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4109.429862344251!2d-81.46979506316825!3d29.99078450127515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e42c63b8cbf159%3A0xbd0a59d33ec97564!2sWorld%20Golf%20Village%20Renaissance%20St.%20Augustine%20Resort!5e0!3m2!1sen!2sus!4v1721353461123!5m2!1sen!2sus"} title={"SUN District 2025 Spring Convention"} date={"May 2nd, 2025 - May 4th, 2025"} addressLink={"https://www.google.com/maps?ll=29.990395,-81.468224&z=15&t=m&hl=en&gl=US&mapclient=embed&cid=13621798786761848164"} street={"500 S Legacy Trail"} state={"St Augustine, FL 32092"} desc={"We're excited to see all of the amazing quartets and choruses representing the Sunshine District compete this year!"} info={"https://www.sunshinedistrict.org/event-calendar/sun-district-2025-spring-convention/"}/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}